import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';


import { App, Lander, Section1, Section2, Section3, Section4, Section5, Section6 } from './App';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />}>
      	<Route index element={<Lander />} />
      	<Route path="section1" element={<Section1 />} />
   		<Route path="section2" element={<Section2 />} />
        <Route path="section3" element={<Section3 />} />
        <Route path="section4" element={<Section4 />} />
        <Route path="section5" element={<Section5 />} />
        <Route path="section6" element={<Section6 />} />
      </Route>
    </Routes>
  </BrowserRouter>
);


